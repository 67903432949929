import {Component} from '@angular/core';
import {ReportBuilderService} from "../../../../services/report-builder.service";
import {Router} from "@angular/router";
import {ProductsService} from "../../../../services/products.service";
import {PureSelectJobComponent} from "./components/pure-select-job/pure-select-job.component";
import {JobSelectedEvent } from "./components/select-job-form/select-job-form.component";

@Component({
    selector: 'app-select-job-to-be-done',
    standalone: true,
    imports: [
        PureSelectJobComponent
    ],
    templateUrl: './select-job-to-be-done.component.html',
    styleUrl: './select-job-to-be-done.component.scss'
})
export class SelectJobToBeDoneComponent {
    company = this.report.company;
    offering = this.report.offering;
    job = this.report.job;
    customers = this.report.customers;

    constructor(private report: ReportBuilderService,
                private router: Router,
                private productsService: ProductsService) {
    }

    selectJob(p: JobSelectedEvent) {
        console.log('[SelectJobToBeDoneComponent] selectJob', p);
        
        this.report.customers.set(p.customers);
        this.report.job.set('_');
        this.report.job.set(p.job);
        this.report.stakeholdersEnabled.set(!!p.stakeholdersEnabled);
        
        this.router.navigate(['/report-builder', 'topics']);
        // this.router.navigate(['/report-builder', 'generate']);
    }

    skipJobSection() {
        this.report.job.set('_');
        this.router.navigate(['/report-builder', 'topics']);
    }

    addJob(job: string) {
        const o = this.offering();
        if (!o) return;

        o.jobs = [...o.jobs, job];
        
        this.productsService.addOffering(o)
            .subscribe(() => {
                console.log('[SelectOffering] addOffering success');                
                this.offering.set({...o});
                // this.selectJob(job);
            });
        
    }
}
