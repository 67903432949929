import {effect, Injectable, WritableSignal} from '@angular/core';
import {SpeechRecognitionService} from "./speech-recognition.service";

@Injectable({
    providedIn: 'root'
})
export class SpeechToTextInputService {
    focusedElement: HTMLInputElement | undefined;
    private _listening: WritableSignal<boolean> | undefined = undefined;

    onListeningChange = effect(() => {
        if (!this.service.recording()) {
            this._reset();
        }
    }, { allowSignalWrites: true })
    
    onTranscriptChange = effect(() => {
        const t = this.service.transcript();
        const el = this.focusedElement;
        if (el) {
            el.value = t;
            el.dispatchEvent(new Event('input', {
                bubbles: true,
                cancelable: true
            }));
        }
    })
    
    constructor(private service: SpeechRecognitionService) {
    }

    listen(listening: WritableSignal<boolean>, input: HTMLInputElement) {
        const reset = this._reset();        
        
        setTimeout(() => {
            this._listening = listening;
            this.focusedElement = input;
            this.service.start();
            input.classList.add('speech-to-text-input');
        }, reset ? 700 : 10);
    }

    stop() {        
        this._reset();             
    }
    
    private _reset() {
        const s = this._listening;
        const el =  this.focusedElement;
        
        if (!s && !el) return false;
        
        if (s) {
            s.set(false);
            this._listening = undefined;            
        }
        
        if (el) {
            el.classList.remove('speech-to-text-input');
            this.focusedElement = undefined;
        }

        this.service.stop();
        
        return true;
    }
}
