<form #outcomeForm="ngForm" (ngSubmit)="next(outcomeForm)">
        
    <h1>2{{ (index + 1) | indexToLetter }}. Did you uncover a @if (index > 0) { {{index + 1 | ordinal}} } problem they want fixed or an improvement they’d like?</h1>

    @if (!skipDisabled) {
        <div class="options flex" qa="opt">
            <div class="sp-selectable" [class.selected]="skip === false" (click)="skip = false">
                <input name="skip" type="radio" id="option1" [value]="false" [(ngModel)]="skip">
                <label for="option1">Yes</label>
            </div>
            
            <div class="sp-selectable" [class.selected]="skip === true" (click)="skip = true">
                <input name="skip" type="radio" id="option2" [value]="true" [(ngModel)]="skip">
                <label for="option2">No. Skip this step.</label>
            </div>
        </div>
    }
    
    <div class="main-content">
        @if (skip === false) {
            <div class="flex section" qa="what-div">
                <div class="what">
                    <h2>What</h2>
                    <p>Can you <em>describe</em> this problem?</p>
                    <p><em>When</em> does it happen?</p>
                    <p><em>Where?</em></p>
                    <p><em>How often?</em></p>
                </div>
                <div class="form">
                    <label for="what">Can you describe it? <app-stt-input-toggle/></label>
                    <textarea appAutofocus appAutosize id="what" [(ngModel)]="data.what" required name="what"></textarea>
                </div>
            </div>
            
            <div class="flex section" qa="why-div">
                <div class="why">
                    <h2>Why</h2>
                    <p>How does this <em>impact</em> them?</p>
                    <p><em>Who</em> does it impact?</p>
                </div>
                <div class="form">
                    <label for="why">How does it impact them? <app-stt-input-toggle/></label>
                    <textarea appAutosize id="why" [(ngModel)]="data.why" name="why"></textarea>
                </div>
            </div>
            
            <div class="flex section" qa="clarify-div">
                <div class="clarify">
                    <h2>Clarify</h2>
                    <p>Begin this with a <em>verb</em> like “minimize”</p> 
                    <p>e.g. “<em>Minimize</em> time to install equipment”</p>
                </div>
                <div class="form">
                    <label for="clarify">The customer would like to… <app-stt-input-toggle/></label>
                    <textarea appAutosize id="clarify" [(ngModel)]="data.clarify" name="clarify"></textarea>
                </div>
            </div>
        }
    </div>
       
    <app-form-navigation-buttons [nextEnabled]="skip === true || (skip === false && !!outcomeForm.valid)" (onPrev)="onPrev.emit()" />
</form>