import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output} from '@angular/core';
import {FormsModule, NgForm, ReactiveFormsModule} from "@angular/forms";
import {CallReportEditData, MeetingType, Person} from "../../../../model";
import {DropdownComponent, IDropdownItem} from "../../../reports/dropdown/dropdown.component";
import {DatePickerComponent} from "../../../../components/date-picker/date-picker.component";
import {InfoPanelComponent} from "../../../../components/info-panel/info-panel.component";
import {DatePipe} from "@angular/common";
import {AutosizeDirective} from "../../../../directives/autosize.directive";
import {AutofocusDirective} from "../../../../directives/autofocus.directive";
import {
    FormNavigationButtonsComponent
} from "../../../../components/form-navigation-buttons/form-navigation-buttons.component";
import {SttInputToggleComponent} from "../../../../components/stt-input-toggle/stt-input-toggle.component";

@Component({
    selector: 'app-meeting-overview-form',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        DropdownComponent,
        DatePickerComponent,
        InfoPanelComponent,
        DatePipe,
        AutosizeDirective,
        AutofocusDirective,
        FormNavigationButtonsComponent,
        SttInputToggleComponent
    ],
    templateUrl: './meeting-overview-form.component.html',
    styleUrl: './meeting-overview-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeetingOverviewFormComponent implements OnChanges {

    @Input() reviewMode = false;
    @Input() data!: CallReportEditData;
    @Output() onPrev = new EventEmitter();
    @Output() onNext = new EventEmitter();
    
    @HostBinding('class.sp-form') f = true;
    @HostBinding('class.sp-form-alternative') a = true;

    showDatePicker = false;
    private _customerCity = '';
    private _customerState = '';

    selectedMeetingType: IDropdownItem | undefined;
    meetingTypes: IDropdownItem[] = [
        { value: MeetingType.InPerson, text: 'In-person' },
        { value: MeetingType.Virtual, text: 'Virtual' },
    ];
    
    get customerCity() {
        return this._customerCity;
    }
    
    get customerState() {
        return this._customerState;
    }
    
    set customerCity(city: string) {
        this._customerCity = city;
        this._updateLocation();        
    }

    set customerState(state: string) {
        this._customerState = state;
        this._updateLocation();        
    }
    
    private _updateLocation() {
        this.data.customerCity = this._customerCity;
        this.data.customerState = this._customerState;
        // this.data.customerCompanyLocation = this._customerState ? `${this._customerCity},${this._customerState}` : this._customerCity;
    }
    
    
    ngOnChanges() {
        const d = this.data;
        this._customerCity = this.data.customerCity;
        this._customerState = this.data.customerState;
        // const c = d.customerCompanyLocation;
        // if (c) {
        //     [this._customerCity, this._customerState] = c.split(',', 2);
        // }
        this.selectedMeetingType = this.meetingTypes.find(c => c.value === d.meetingType);
    }

    next(f: NgForm) {
        if (f.value && this.data.meetingType > 0) {
            this.onNext.emit();            
        }
    }
    
    prev() {
        this.onPrev.emit();        
    }

    addInterviewer() {
        this.data.interviewers.push({name: '', title: ''});
    }

    addInterviewee() {
        this.data.interviewees.push({name: '', title: ''});
    }
}

