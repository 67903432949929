import {Component, computed, HostBinding, HostListener, OnDestroy, signal} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {ReportService} from "../../services/report.service";
import {Title} from "@angular/platform-browser";
import {Subscription} from "rxjs";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {MessageService} from "../../services/message.service";
import {PureReportViewerComponent} from "./components/pure-report-viewer/pure-report-viewer.component";
import {
    SetMeetingDateArgs
} from "../report-builder/screens/set-meeting-date/components/pure-set-meeting-date/pure-set-meeting-date.component";
import {DiscussionTopic, ExploratoryQuestion, ReportSectionRating, Stakeholder} from "../../model";
import {RatingService} from "../../services/rating.service";
import {InfoPanelComponent} from "../../components/info-panel/info-panel.component";
import {RateSectionDialogComponent} from "../../components/rate-section-dialog/rate-section-dialog.component";
import {AuthService} from "../../services/auth.service";
import {PdfExportService} from "../../services/pdfExport.service";
import {StateService} from "../../services/state.service";
import {IEditableDiscussionTopics} from "../../services/report-builder.service";
import {FooterSmartComponent} from "../../components/footer-smart/footer-smart.component";
import {PageHeaderComponent} from "../../components/page-header/page-header.component";
import {toObservable} from "@angular/core/rxjs-interop";
import {BriefReportsService} from "../../services/brief-reports/brief-reports.service";
import {CustomerHandoutArgs} from "../../services/customer-handouts/customer-handouts-generator";
import {TutorService} from "../../services/tutor.service";

@Component({
    selector: 'app-report-viewer',
    standalone: true,
    imports: [
        MainHeaderComponent,
        PureReportViewerComponent,
        InfoPanelComponent,
        RateSectionDialogComponent,
        FooterSmartComponent,
        PageHeaderComponent
    ],
    host: {ngSkipHydration: 'true'},
    templateUrl: './report-viewer.component.html',
    styleUrl: './report-viewer.component.scss'
})
export class ReportViewerComponent implements OnDestroy {

    subs: Subscription[] = [];
    exporting = signal(false);

    reportIsMissing = this.reportService.reportIsMissing;
    reportDataLoading = this.reportService.reportDataLoading;
    reportData = this.reportService.reportData;

    companyDataLoading = this.reportService.companyDataLoading;
    company = this.reportService.companyData;

    exploratoryQuestions = computed(() => {
        return this.reportData()?.exploratoryQuestions ?? [];
    })
    stakeholders = computed(() => {
        return this.reportData()?.stakeholders ?? [];
    })
    newsArticles = computed(() => {
        return this.reportData()?.newsArticles ?? [];
    })
    discussionTopics = computed(() => {
        return this.reportData()?.discussionTopics ?? [];
    })

    discussionTopicsEditable = signal<IEditableDiscussionTopics[] | undefined>(undefined);

    /*
    marketTrends = computed(() => {
        return this.reportData()?.marketTrends ?? [];
    }) 
    commonProblems = computed(() => {
        return this.reportData()?.commonProblems ?? [];
    }) 
    processSteps = computed(() => {
        return this.reportData()?.processSteps ?? [];
    })
    */
    otherTopics = computed(() => {
        return this.reportData()?.otherTopics ?? [];
    })

    authenticated = this.authService.authenticated;
    userInitials = this.authService.userInitials;

    ratings = this.ratingService.ratings;

    editExploratoryQuestions = false;
    editMeetingDate = false;
    badReportSectionRating: ReportSectionRating | undefined;
    
    
    constructor(private messageService: MessageService,
                private pdfExportService: PdfExportService,
                private tutorService: TutorService,
                private stateService: StateService,
                private authService: AuthService,
                private ratingService: RatingService,
                private router: Router,
                private reportService: ReportService,
                private briefReportsService: BriefReportsService,
                route: ActivatedRoute, title: Title) {
        const id = route.snapshot.params['id'];
        if (!id) {
            throw Error('ID is undefined');
        }
        reportService.selectedReportId.set(id);
        
        const sub = toObservable(reportService.reportData).subscribe(s => title.setTitle(
            `${s?.company.name} - ${s?.subject}`
        ));
        this.subs.push(sub);
    }

    ngOnDestroy() {
        this.subs.forEach(s => s.unsubscribe());
    }

    regenerate() {
        this.messageService.notImplemented()
        // this.reportService.regenerate();
    }

    back() {
        history.back();
    }

    updateReport() {
        console.log('[ReportViewerComponent] updateReport');
        this.reportService.updateReport().subscribe(() => {
            this.cancelEditMode();
            this.messageService.success('Changes have been saved');
        });
    }

    saveExploratoryQuestions(list: ExploratoryQuestion[]) {
        console.log('[ReportViewerComponent] saveExploratoryQuestions', list);
        const r = this.reportData();
        if (!r) throw Error('Report is not defined');

        r.exploratoryQuestions = list;

        this.reportService.updateReport().subscribe(() => {
            this.cancelEditMode();
            this.messageService.success('Changes have been saved');
        });
    }
        
    updateMeetingDate(args: SetMeetingDateArgs) {
        console.log('[ReportViewerComponent] updateMeetingDate', args);

        const r = this.reportData();
        if (!r) throw Error('Report is not defined');

        r.customerCity = args.customerCity;
        r.customerState = args.customerState;
        r.meetingType = args.meetingType;
        r.meetingDate = args.date;
        r.meetingTime = args.time;

        this.reportService.updateReport().subscribe(() => {
            this.cancelEditMode();
            this.messageService.success('Changes have been saved');
        });
    }

    scrollToTop(animated = false) {
        window.scroll({
            top: 0,
            left: 0,
            behavior: animated ? 'smooth' : 'instant'
        });
    }

    cancelEditMode() {
        this.discussionTopicsEditable.set(undefined);
        this.editMeetingDate = false;
        this.editExploratoryQuestions = false;
        this.scrollToTop();
    }

    rate(rating: ReportSectionRating, closeDialog = false) {
        console.log('[ReportViewerComponent] rate', rating);

        if (rating.rating === false) {
            this.badReportSectionRating = rating;
        }

        this.ratingService.rateReportSection(rating).subscribe(() => {
            console.log('[ReportViewerComponent] rate success');
            if (closeDialog) {
                this.badReportSectionRating = undefined;
            }
        });
    }

    navigateToTutor(topic = '') {
        const data = this.reportData();

        if (this.authenticated()) {
            const r = this.reportData();
            if (r) {
                this.tutorService.customers.set(r.customers);
                this.router.navigate(['/ai-guide', r!.offeringId, r!.job || '_', topic]);
            }
        } else {
            this.router.navigate(['/try-ai-guide', data?.offeringName, data?.job, topic]);
        }
    }
        
    @HostListener('window:keydown.control.p', ['$event'])
    async print(e: Event) {
        e.preventDefault();
        await this.export();
    }

    async export() {
        const data = this.reportData();
        if (data) {

            try {
                this.stateService.busy.set(true);
                this.exporting.set(true);
                await this.pdfExportService.export(data);
            } finally {
                this.stateService.busy.set(false);
                this.exporting.set(false);
            }
        }
    }

    async exportCustomerHandouts(args: CustomerHandoutArgs) {        
        const data = this.reportData();
        if (data) {
            try {
                this.stateService.busy.set(true);
                this.exporting.set(true);
                await this.pdfExportService.exportCustomerHandouts(data, args);
            } finally {
                this.stateService.busy.set(false);
                this.exporting.set(false);
            }
        }
    }

    editDiscussionTopics() {
        this.discussionTopicsEditable.set(this.discussionTopics().map(topics => {
            return {
                role: topics.role,

                marketTrends: signal<DiscussionTopic[]>(topics.marketTrends),
                commonProblems: signal<DiscussionTopic[]>(topics.commonProblems),
                processSteps: signal<DiscussionTopic[]>(topics.processSteps),

                marketTrendsLoading: signal<boolean>(false),
                commonProblemsLoading: signal<boolean>(false),
                processStepsLoading: signal<boolean>(false),
                marketTrendsLoadingFailed: signal<boolean>(false),
                commonProblemsLoadingFailed: signal<boolean>(false),
                processStepsLoadingFailed: signal<boolean>(false),
            }
        }));
    }
    jobDescriptionReport(stakeholder: Stakeholder) {
        console.log('[ReportViewerComponent] jobDescriptionReport', stakeholder);

        this.briefReportsService.loadJobDescriptionReport(stakeholder, this.reportData()!)
            .subscribe({
                next: async (report) => {
                    if (report) await this.pdfExportService.exportJobDescriptionBriefReport(report);
                },
                error: () => this._refreshStakeholder(stakeholder),
                complete: () => this._refreshStakeholder(stakeholder),
            });
    }
    
    private _refreshStakeholder(stakeholder: Stakeholder) {
        stakeholder.reportGenerating = false;

        const data = this.reportData();
        if (data) {
            data.stakeholders = data.stakeholders.map(x => x == stakeholder ? { ...stakeholder } : x);
            this.reportData.set({...data});
        }
    }
    
    trendsBriefReport(topic: DiscussionTopic) {
        console.log('[ReportViewerComponent] trendsBriefReport(topic)', topic);
        this.briefReportsService.loadTrendReport(topic, this.reportData()!)
            .subscribe({
                next: async (report) => {
                    if (report) await this.pdfExportService.exportTrendBriefReport(report);
                },
                error: () => this._refreshUI(topic),                
                complete: () => this._refreshUI(topic),                
            });
    }

    problemsBriefReport(topic: DiscussionTopic) {
        console.log('[ReportViewerComponent] problemsBriefReport(topic)', topic);
        this.briefReportsService.loadProblemReport(topic, this.reportData()!)
            .subscribe({
                next: async (report) => {
                    if (report) await this.pdfExportService.exportProblemBriefReport(report);
                },
                error: () => this._refreshUI(topic),
                complete: () => this._refreshUI(topic),
            });
    }
    
    processBriefReport(topic: DiscussionTopic) {
        console.log('[ReportViewerComponent] processBriefReport(topic)', topic);
        this.briefReportsService.loadProcessReport(topic, this.reportData()!)
            .subscribe({
                next: async (report) => {
                    if (report) await this.pdfExportService.exportProcessBriefReport(report);
                },
                error: () => this._refreshUI(topic),
                complete: () => this._refreshUI(topic),
            });
    }

    rolePlay(topic: DiscussionTopic) {
        console.log('[ReportViewerComponent] rolePlay(topic)', topic);
        this.navigateToTutor(topic.title);        
    }
    
    private _refreshUI(topic: DiscussionTopic) {
        topic.reportGenerating = false;
        
        const data = this.reportData();
        if (data) {
            data.discussionTopics = data.discussionTopics.map(topics => {
                topics.marketTrends = topics.marketTrends.map(x => x == topic ? {...topic} : x);
                topics.processSteps = topics.processSteps.map(x => x == topic ? {...topic} : x);
                topics.commonProblems = topics.commonProblems.map(x => x == topic ? {...topic} : x);
                return {...topics};
            });
            this.reportData.set({...data});
        }
    }
}
