import {Component, HostBinding, OnInit} from '@angular/core';
import {FooterSmartComponent} from "../../components/footer-smart/footer-smart.component";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {PageHeaderComponent} from "../../components/page-header/page-header.component";
import {SpinnerComponent} from "../../components/spinner/spinner.component";
import {HttpClient} from "@angular/common/http";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
    selector: 'app-webinars',
    standalone: true,
    imports: [
        FooterSmartComponent,
        MainHeaderComponent,
        PageHeaderComponent,
    ],
    templateUrl: './webinars.component.html',
    styleUrl: './webinars.component.scss'
})
export class WebinarsComponent implements OnInit {
    
    private _url = '/api/webinars';
    @HostBinding('class.sp-typography') t = true;

    public safeHtml: SafeHtml | undefined;
    
    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
    }
    
    async ngOnInit() {
        const url = location.origin + this._url;
        this.http.get(url, { responseType: 'text' })
            .subscribe({
                next: (htmlContent: string) => {
                    this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(htmlContent);
                },
                error: (err) => {
                    console.error('Error loading external HTML:', err);
                },
            });
    }

}
