import {PdfGenerator} from "../pdf-generator";
import {OfferingActionPipe} from "../../../pipes/offering-action.pipe";
import {IReportPart} from "../ireport-part";
import {MeetingTypePipe} from "../../../pipes/meeting-type.pipe";

interface ILogoImage {
    img: HTMLImageElement,
    width: number;
    height: number;
}
export class CompanyOverviewSection implements IReportPart {
    
    private _logoData: ILogoImage | undefined;
    
    constructor(private pdf: PdfGenerator) {
    }
    
    async loadLogo() {
        const url = this.pdf.report.company.logoUrl;
        if (url) {
            try {
                this._logoData = await this._loadLogo(url);
            } catch (e) {
                console.error(e);
            }            
        }        
    }
    
    private _loadLogo(url: string): Promise<ILogoImage> {
        return new Promise((resolve, reject) => {
            let img: HTMLImageElement;
            img = new Image();
            img.src = url;

            img.onload = function() {
                resolve({ img, width: img.width, height: img.height });
            };

            img.onerror = function() {
                reject(new Error('Failed to load image at ' + url));
            };
        });
    }
    draw() {
        const pdf = this.pdf;
        const r = pdf.report;
        const doc = pdf.doc;
        doc.setTextColor('#000');
        pdf.text(r.company.name, 46.652, 121.649, 'Raleway-ExtraBold', 18);
        
        let offsetX = 0;
        if (this._logoData) {
            const logo = this._logoData;
            const ratio = logo.width / logo.height;
            console.log('[CompanyOverviewSection] ratio', ratio);
            offsetX = ratio * 40;
            doc.addImage(logo.img, 'png', 49.829, 61.198, offsetX, 40);
        }

        if (r.company.website) {
            doc.setTextColor(pdf.styles.linkColor);
            const domain = r.company.website.replace(/^(?:https?:\/\/)?/i, '').split('/')[0];
            pdf.text(domain, 46.652, 139.287, 'Raleway-Medium', 9);
        }

        doc.setFontSize(13);
        doc.setTextColor('#000');
        const meetingDateY = 163.848;
        let w = pdf.text('Meeting: ', 45.730, meetingDateY, 'Raleway-Regular');
        if (r.meetingDate) {
            let w2 = pdf.text(pdf.datePipe.transform(r.meetingDate) || '', 45.730 + w, meetingDateY, 'Raleway-Bold');
            w2 += pdf.text(' ' + pdf.timePipe.transform(r.meetingTime) || '', 45.730 + w + w2, meetingDateY, 'Raleway-Bold');
            
            if (r.meetingType) {
                const pipe = new MeetingTypePipe();
                w2 += pdf.text(' | ' + pipe.transform(r.meetingType), 45.730 + w + w2, meetingDateY, 'Raleway-Regular');
            }
            
            if (r.customerCity) {
                w2 += pdf.text(' | ' + r.customerCity, 45.730 + w + w2, meetingDateY, 'Raleway-Regular');
                if (r.customerState) {
                    pdf.text(', ' + r.customerState, 45.730 + w + w2, meetingDateY, 'Raleway-Regular');
                }
            }
        } else {
            pdf.text('not set', 45.730 + w, meetingDateY, 'Raleway-Bold');
        }

        let offeringY = 180;
        w = pdf.text('Offering: ', 45.730, offeringY, 'Raleway-Regular');
        const w2 = pdf.text(r.offeringName, 45.730 + w, offeringY, 'Raleway-Bold');
        if (r.job) {
            const text = new OfferingActionPipe().transform(r.offeringType, true)
            pdf.text(' ' + text + ' ' + r.job, 45.730 + w + w2, offeringY, 'Raleway-Regular');
        }

        const roles = r.customers.map(x => x.title).filter(x => !!x);
        if (roles.length) {
            offeringY += 17;
            w = pdf.text('Customer Roles: ', 45.730, offeringY, 'Raleway-Regular');
            pdf.text(roles.join(', '), 45.730 + w, offeringY, 'Raleway-Bold');
        }        

        const marginBottom = 25;
        return offeringY + marginBottom;
    }
}