<div class="cell">
    <div class="stop">
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>

<div class="cell">
    <app-recording-animation class="started" />
    <div class="start">
        <span class="material-symbols-outlined mic">mic</span>
    </div>
</div>