<div class="header">
    <div class="text" [innerHTML]="formattedText"></div>

    <app-speaking-animation [class.speaking]="speaking" />
</div>

@if (!reviewMode) {
    <div class="input-wrapper">
        @if (options?.length) {
            <div class="options">
                @for (o of options; track o.title) {
                    <div (click)="onSelect.emit(o)" class="option" [class.selected]="o.selected" [innerHTML]="o.title"></div>
                }
            </div>
        } @else if (options?.length !== 0) {
            <div class="input">
                <textarea #textAreaElement class="textarea" [(ngModel)]="_input" (ngModelChange)="textChange()"></textarea>
            </div>
        }
    
        @if (options?.length !== 0) {
            <app-voice-input-toggle [listening]="listening"
                                    (click)="onToggleListening.emit()"
                                    [class.options-input]="options?.length"
                                     />
<!--            <div class="listening-wrapper" >-->
<!--                <button type="button" (click)="onToggleListening.emit()" [class.listening]="listening" title="Click to speak, or simply press the spacebar">-->
<!--                    <app-recording-animation />-->
<!--                    <span class="material-symbols-outlined mic">mic</span>-->
<!--                </button>-->
<!--            </div>-->
        }
    </div>
    
    <app-form-navigation-buttons (onPrev)="prev()" (onNext)="next()" [nextTitle]="nextTitle" />
}