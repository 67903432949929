export interface CallReportRecodedOutcome
{
    what: string;
    why: string;
    clarify: string;
}

export interface CallReportTopic {
    title: string;
    description: string;
}

export interface CallReportGeneratedOutcome {
    title: string;
    description: string;
}

export enum MeetingType {
    Unknown = 0,
    InPerson,
    Virtual
}

export interface Person {
    name: string;
    title: string;
}

export interface CallReportEditData {
    step: number;
    
    reportId: string;
    customerCompany: string;
    customerCity: string;
    customerState: string;
    meetingType: MeetingType;
    date?: Date;

    job: string;
    
    purpose: string;
    scope: string;
    
    interviewers: Person[];
    interviewees: Person[];

    outcomes: CallReportRecodedOutcome[];

    insights: string;
    opportunities: string;
    nextSteps: string;
    notes: string;
}

export interface CallReportRequest extends CallReportEditData {
    offering: string;
    meetingDate: string;

    companyDescription: string;
    companyStrategy: CallReportTopic[];
    companyNews: CallReportTopic[];
    marketTrends: CallReportTopic[];
    commonProblems: CallReportTopic[];
    processSteps: CallReportTopic[];
}

export interface CallReport extends CallReportGeneratedData {
    // version: string;
    // authorName: string;    
}

export interface CallReportGeneratedData {
    isGeneratedDataOverridden: boolean;
    meetingDate: string;
    meetingType: MeetingType;
    customerCity: string;
    customerState: string;
    customerCompany: string;
    meetingOverview: string;
    outcomes: CallReportGeneratedOutcome[];
    preCallResearch: string;
    otherInsights: string;
    opportunities: string;
    nextSteps: string[];    
}