import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'customerLocation',
    standalone: true
})
export class CustomerLocationPipe implements PipeTransform {

    transform(value: CustomerLocation | undefined, ...args: unknown[]): string {
        if (!value) {
            return '';
        }
        const city = value.customerCity;
        const state = value.customerState;
        
        if (city) {
            return state ? `${city}, ${state}` : city;
        }
        return state ?? '';
    }
}

export interface CustomerLocation {
    customerCity: string;
    customerState: string;
}