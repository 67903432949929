<app-main-panel>
    
    <div class="container">

        <div class="header">
            Want to sell more? Simple.
        </div>

        <div class="sp-typography buttons">
            <p class="note">
                Ask more (and better) questions with the SalesPrep system.
            </p>

            <div class="image mobile">
                <img src="./assets/images/splash/bg.jpg" alt="Salesperson interviewing B2B customer"/>
            </div>
            
            <div class="flex-column buttons-group">
                <button [routerLink]="[authenticated ? '/dashboard' : '/signup']" class="primary" qa="start-1">
                    Start {{ trialDays }}-day trial
                    <br>
                    <span>(No credit card needed)</span>
                </button>

                <button [routerLink]="['/webinars']" class="primary webinar" qa="webinar">
                    Free webinar
                    <br>
                    <span>Learn all about it in 30 minutes!</span>
                </button>
    
                <!--<div class="pricing">
                    Enterprise and other options available.<br>
                    <a [routerLink]="['/pricing']">See pricing</a> for information.
                </div>-->

                <button class="alternative login" title="Log in"  routerLink="/login" qa="login">Log in</button>
            </div>
        </div>
        
        <div class="image desktop">
            <img src="./assets/images/splash/bg.jpg" alt="Salesperson interviewing B2B customer"/>
        </div>
    </div>

</app-main-panel>