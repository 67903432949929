@if (listening()) {
    <span title="Click to exit from speech-to-text mode" class="material-symbols-outlined listening">
        speech_to_text        
    </span>
<!--    mic_off-->
    
} @else {
    <span title="Click to enable speech-to-text mode" class="material-symbols-outlined">speech_to_text</span>
<!--    mic-->
<!--    <span class="material-symbols-outlined">mic_off</span>-->
}