import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CallReportGeneratedOutcome, MeetingType, SalesPrepReport} from "../../../../model";
import {PageHeaderComponent} from "../../../../components/page-header/page-header.component";
import {OfferingActionPipe} from "../../../../pipes/offering-action.pipe";
import {DatePipe} from "@angular/common";
import {CompanyLogoComponent} from "../../../../components/company-logo/company-logo.component";
import {RouterLink} from "@angular/router";
import {FormatTextPipe} from "../../../../pipes/format-text.pipe";
import {CustomerLocationPipe} from "../../../../pipes/customer-location.pipe";

@Component({
    selector: 'app-pure-call-report-viewer',
    standalone: true,
    imports: [
        PageHeaderComponent,
        DatePipe,
        CompanyLogoComponent,
        RouterLink,
        FormatTextPipe,
        CustomerLocationPipe
    ],
    templateUrl: './pure-call-report-viewer.component.html',
    styleUrl: './pure-call-report-viewer.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureCallReportViewerComponent implements OnChanges {
    @Input() userName = '';
    @Input() report!: SalesPrepReport;
    @Input() reportLoading = false;
    
    @Input() meetingDate: string | null = null;
    
    @Output() onEditMeetingOverview = new EventEmitter<string>();
    @Output() onEditResearch = new EventEmitter<string>();
    @Output() onEditInsights = new EventEmitter<string>();
    @Output() onEditOpportunities = new EventEmitter<string>();
    @Output() onEditNextStep = new EventEmitter<number>();
    @Output() onEditOutcome = new EventEmitter<number>();
    @Output() onRegenerate = new EventEmitter();
    @Output() onDownload = new EventEmitter();
    @Output() onGenerateEmail = new EventEmitter();
    
    headerTitle = '';
    headerText = '';
    
    MeetingType = MeetingType;
    _datePipe = new DatePipe('en-US');
    
    _locationPipe = new CustomerLocationPipe();
    
    ngOnChanges() {
        const r = this.report;
        const callReport = r.callReport;
        this.headerTitle = 'Call Report — ' + (this._datePipe.transform(callReport?.meetingDate) ?? this.meetingDate);

        const company = callReport?.customerCompany ?? r.company.name;
        const action = new OfferingActionPipe().transform(r.offeringType, false);
        this.headerText = `${company} | Use <strong>${r.offeringName}</strong> ${action} ${r.job}`;
        if (callReport?.customerCity || callReport?.customerState) {
            this.headerText += ' | ' + this._locationPipe.transform(callReport);
        }
    }
}
