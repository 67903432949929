import {CallReportVoiceInputSteps} from "./call-report-voice-input-steps";
import {CallReportEditData} from "../../model";

export interface CallReportVoiceInputStepOptions {
    title: string;
    transcript: string;
    selected?: boolean;
}

export abstract class CallReportVoiceInputStep {

    protected constructor(
        public readonly step: CallReportVoiceInputSteps,
        public readonly text: string) {}
        
    complete(data: CallReportEditData, transcript: string, index: number): CallReportVoiceInputSteps {
        return this.step;
    };

    formatText(data: CallReportEditData, index: number) {
        return this.text;
    }
    
    getValue(data: CallReportEditData, index: number): string {
        return '';        
    }
}

export abstract class CallReportVoiceInputStepWithOptions extends CallReportVoiceInputStep {
    protected constructor(
        step: CallReportVoiceInputSteps,
        text: string,
        public options: CallReportVoiceInputStepOptions[]) {
        super(step, text);
    }

    select(o: CallReportVoiceInputStepOptions) {
        console.log('[CallReportVoiceInputStepWithOptions] select: ', o);
        this.options.map(x => x.selected = false);
        o.selected = true;
        this.options = [...this.options];
    }
}
