import {Component, computed, OnDestroy, OnInit} from '@angular/core';
import {toSignal} from "@angular/core/rxjs-interop";
import {filter, map, Subscription} from "rxjs";
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from "@angular/router";
import {FooterCompactComponent} from "../../components/footer-compact/footer-compact.component";
import {MainHeaderComponent} from "../../components/main-header/main-header.component";
import {ReportService} from "../../services/report.service";
import {Title} from "@angular/platform-browser";
import {MobileNavHeaderComponent} from "../../components/mobile-nav-header/mobile-nav-header.component";
import {AuthService} from "../../services/auth.service";
import {SideProgressBarComponent} from "../report-builder/components/side-progress-bar/side-progress-bar.component";
import {CallReportBuilderService} from "../../services/call-report-builder.service";
import {IndexToLetterPipe} from "../../pipes/index-to-letter.pipe";
import {FooterSmartComponent} from "../../components/footer-smart/footer-smart.component";
import {SpeechRecognitionService} from "../../services/speech-recognition.service";
import {SpeechSynthesisService} from "../../services/speech-synthesis.service";
import {CustomerLocationPipe} from "../../pipes/customer-location.pipe";

@Component({
    selector: 'app-call-report-builder',
    standalone: true,
    imports: [
        MainHeaderComponent,
        RouterOutlet,
        MobileNavHeaderComponent,
        SideProgressBarComponent,
        FooterSmartComponent,
        CustomerLocationPipe
    ],
    templateUrl: './call-report-builder.component.html',
    styleUrl: './call-report-builder.component.scss'
})
export class CallReportBuilderComponent implements OnInit, OnDestroy {

    data = this.reportBuilder.inputData;
    reportData = this.reportService.reportData;
    meetingDate = computed(() => {
        const d = this.reportData()?.meetingDate;
        if (!d) {
            return undefined;
        }
        return new Date(d);
    });

    offering = computed(() => {
        const d = this.reportData();
        if (!d) {
            return undefined;
        }
        return this.authService.offerings()?.find(x => x.id == this.reportData()?.offeringId)
    });
    
    stepIndex = 1;
    
    stepTitle = toSignal(this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map(route => {
            while (route.firstChild) {
                route = route.firstChild;
            }
            let title = route.title ?? '';
            this.stepIndex = 1;
            
            if (title) {
                this.stepIndex = +title.substring(0, 1);

                const step = route.params['step'] ?? route.params['index'];
                if (step) {
                    const args = title.split('.', 2);
                    const stepTitle = this.indexToLetterPipe.transform(step);
                    title = `${args[0]}${stepTitle}. ${args[1]}`;
                }
            }
            
            return title;            
        })        
    ));

    private subs: Subscription[] = [];
    private indexToLetterPipe = new IndexToLetterPipe();
    
    constructor(private router: Router, private route: ActivatedRoute,
                private authService: AuthService, 
                private reportService: ReportService,
                private speechSynthesisService: SpeechSynthesisService,
                private speechRecognitionService: SpeechRecognitionService,
                private reportBuilder: CallReportBuilderService,
                private title: Title) {       
    }
    
    ngOnInit() {
        const id = this.route.snapshot.params['id'];
        if (!id) {
            throw Error('ID is undefined');
        }
        this.reportService.selectedReportId.set(id);

        // const sub = toObservable(this.reportService.reportData).subscribe(s => 
        //     this.title.setTitle(`${s?.company.name} - ${s?.subject}`
        // ));
        // this.subs.push(sub);
    }

    ngOnDestroy() {
        this.subs.forEach(s => s.unsubscribe());
        this.speechSynthesisService.stop();
        this.speechRecognitionService.stop();
    }

    back() {
        window.history.back();
    }
}
