import {Pipe, PipeTransform} from '@angular/core';
import {MeetingType} from "../model";

@Pipe({
    name: 'meetingType',
    standalone: true
})
export class MeetingTypePipe implements PipeTransform {

    transform(value: MeetingType, ...args: unknown[]): string {
        switch (value) {
            case MeetingType.InPerson:
                return 'In-person';
            case MeetingType.Virtual:
                return 'Remote';
            default:
                return '';
        }
    }

}
