import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SalesPrepReport} from "../model";
import {AuthService} from "./auth.service";
import { MeetingTypePipe } from '../pipes/meeting-type.pipe';

@Injectable({
    providedIn: 'root'
})
export class CustomerRecapService {
    
    _meetingTypePipe = new MeetingTypePipe();

    constructor(private http: HttpClient, private authService: AuthService) {
    }

    downloadEmailFile(report: SalesPrepReport): Observable<Blob> {
        
        const r = report.callReport;
        if (!r) throw Error('No Report');

        const userName = this.authService.userName();
        let text = '# Customer Meeting Report';
        
        text += '\nAuthor: ' + userName;
        text += '\nDate: ' + r.meetingDate;
        text += '\nMeeting Type: ' + this._meetingTypePipe.transform(r.meetingType);
        if (r.customerCity) {
            text += '\nCustomer City: ' + r.customerCity;
        }
        if (r.customerState) {
            text += '\nCustomer State: ' + r.customerState;
        }
        
        text += '\n## Meeting Overview\n' + r.meetingOverview;
        text += '\n## Desired Customer Outcomes:';
        r.outcomes.map(x => text += `\n - **${x.title}**: ${x.description}`);
        text += '\n## Pre-Call Research\n' + r.preCallResearch;
        text += '\n## Opportunities\n' + r.opportunities;
        text += '\n## Next Steps:';
        r.nextSteps.map(x => text += '\n - ' + x);
        
        
        return this.http.post(`/api/call-reports/generate-recap`,
            {
                CallReport: text 
            },
            { responseType: 'blob' }
        );
    }
}
