import {ChangeDetectionStrategy, Component, ElementRef, HostBinding, HostListener, signal} from '@angular/core';
import {SpeechToTextInputService} from "../../services/speech-to-text-input.service";

@Component({
    selector: 'app-stt-input-toggle',
    standalone: true,
    imports: [],
    templateUrl: './stt-input-toggle.component.html',
    styleUrl: './stt-input-toggle.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SttInputToggleComponent {

    @HostBinding('class.listening')
    get l() {
        return this.listening(); // Returns the current value of the signal
    }
    
    listening = signal(false);

    constructor(public element: ElementRef, private service : SpeechToTextInputService) {
    }

    @HostListener('click') onClick() {
        if (this.listening()) {
            this.stop();
            this.listening.set(false);
        } else {
            this.start();
            this.listening.set(true);
        }
    }
    private stop() {
        this.service.stop();
    }

    private start() {        
        const id = this.element.nativeElement.parentElement.getAttribute('for');
        if (!id) {
            console.error('[SpeechToTextDirective] id is empty');
        }
        const el = document.getElementById(id);
        if (el) {
            this.service.listen(this.listening, <HTMLInputElement> el);
        } else {
            console.error('[SpeechToTextDirective] element not found: ', id);
        }
    }

}
