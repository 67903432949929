<app-top-progress-bar [step]="3"/>
<app-side-progress-bar [step]="3" [company]="company" [offering]="offering" />

<app-main-panel class="sp-form left-column">
    <app-report-builder-header class="mobile-layout" [company]="company" [offering]="offering"/>

    <!--<div class="header" qa="sel3-job">
        <strong>
            <span class="mobile-layout">3. </span>Select customer’s the job-to-be-done</strong> with {{ offering.name }}
            that you plan to discuss with {{ company.name }}.
    </div>-->

    <app-select-job-form
            [company]="company"
            [offering]="offering"
            [customers]="customers"
            [job]="job"
            (onSelect)="onSelect.emit($event)"
            (onAddNew)="addNew = true; job = ''"
            (onSkip)="onSkip.emit()"
            (onBack)="goBack()"
        />
    
    

</app-main-panel>

@if (addNew) {
    <app-info-panel [title]="'Add job-to-be-done'" (onClose)="addNew = false">
        <form (ngSubmit)="onAdd.emit(job);addNew = false" class="sp-form sp-form-alternative">
            <label for="job">Customers use this offering {{ offering.type | offeringAction:true }} {{ offering.type | offeringActionTip}}</label>        
            <input appAutofocus type="text" id="job" name="job" [(ngModel)]="job">        
            <button class="primary">Add Job-to-be-done</button>
        </form>
    </app-info-panel>    
}