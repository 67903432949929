@if (companyNotFound) {
    <div class="tips" qa="tips">
        <span qa="tip-h">We couldn't find your company. These tips may help:</span>

        <ul qa="tip-b">
            <li>Check your spelling.</li>
            <li>Enter the company's website URL.</li>
            <li>Enter whatever you know about the company (e.g., "paper mill in Ohio").</li>
            <li>
                <a (click)="onNext.emit()" qa="tip-c">Create a report</a> (which may lack information on this company)
            </li>
        </ul>
    </div>
}


