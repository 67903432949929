<app-main-header class="sp-desktop" [sticky]="false"/>

@if (report()) {
    <app-pure-call-report-viewer
            (onEditMeetingOverview)="editMeetingOverview = true; edit($event)"
            (onEditResearch)="editResearch = true; edit($event)"
            (onEditInsights)="editInsights = true; edit($event)"
            (onEditOpportunities)="editOpportunities = true; edit($event)"
            (onEditNextStep)="_editNextStep($event)"
            (onEditOutcome)="_editOutcome($event)"
            (onRegenerate)="regenerate()"
            (onDownload)="downloadPdf()"
            (onGenerateEmail)="generateEmail()"
            [userName]="userName()"
            [report]="report()!"
            [meetingDate]="report()!.meetingDate | date"    
    />

    @if (report()!.callReport) {
        @if (editing) {
            <app-info-panel [title]="formTitle" (onClose)="stopEdit()">
                <form #editForm="ngForm" (ngSubmit)="save(!!editForm.valid);" class="sp-form sp-form-alternative">
                    @if (editOutcome) {
                        <input type="text" appAutofocus id="title" name="title" required [(ngModel)]="editOutcome.title">
                        <textarea appAutosize id="desc" name="desc" rows="5" required [(ngModel)]="editOutcome.description"></textarea>
                    } @else {
                        <textarea appAutosize appAutofocus id="text" name="text" rows="5" required [(ngModel)]="formText"></textarea>
                    }
                    
                    @if (saving) {
                        <app-spinner/>
                    } @else {
                        
                        <div class="flex buttons">       
                            @if (editOutcomeIndex > 0 || editNextStepIndex > 0) {
                                <button (click)="delete()" type="button">Delete</button>
                            }
                            <button [disabled]="!editForm.valid" class="primary">Save</button>
                        </div>
                    }
                </form>
            </app-info-panel>
        }
    }
    
} @else {
    <app-spinner/>
}

<app-footer-smart/>

