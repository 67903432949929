import {Pipe, PipeTransform} from '@angular/core';
import {Person} from "../model";

@Pipe({
    name: 'roles',
    standalone: true
})
export class RolesPipe implements PipeTransform {

    transform(persons: Person[] | undefined): unknown {
        if (!persons) {
            return '';
        }
        
        return persons.map(x => x.title).filter(x => !!x).join(', ');
    }
}
