import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

declare var grecaptcha: any;

const KEY = '6LctgUAqAAAAABBGNeDGCVQ5VOSwbhytE1tL-HGN';

@Injectable({
    providedIn: 'root'
})
export class RecaptchaService {
    private loaded: boolean = false;

    constructor(@Inject(DOCUMENT) private readonly document: Document) {
    }

    public load(): void {
        if (this.loaded) {
            return;
        }
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${KEY}`;
        this.document.head.appendChild(script);
        this.loaded = true;
    }

    public execute() {
        return new Promise<string>((_resolve, reject) => {
            grecaptcha.ready(() => {
                grecaptcha.execute(KEY, {action: 'submit'})
                    .then(_resolve);
            });
        })
    }
}