export enum CallReportVoiceInputSteps {
    Start,
    CustomerLocation,
    MeetingType,
    MeetingPurpose ,
    ScopeReview,
    ScopeUpdate,
    
    Interviewers,
    InterviewersNames,

    IntervieweesReview,
    IntervieweesStep,
    
    ReviewMeetingOverviewQuestion,
    ReviewMeetingOverview,
    
    OutcomesQuestion,
    OutcomeWhat,
    OutcomeWhy,
    OutcomeClarify,
    
    Insights,
    Opportunities,
    NextSteps,
    Notes,
}