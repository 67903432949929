import {CallReportVoiceInputStep, CallReportVoiceInputStepWithOptions} from "./model";
import {CallReportVoiceInputSteps} from "./call-report-voice-input-steps";
import {CallReportEditData} from "../../model";
import {OrdinalLongPipe} from "../../pipes/ordinal-long.pipe";

export class InterviewersStep extends CallReportVoiceInputStepWithOptions {
    constructor() {
        super(
            CallReportVoiceInputSteps.Interviewers,
            'Did any of your colleagues join you?',
            [
                { title: '<em>Yes</em>.', transcript: 'yes' },
                { title: '<em>No</em>. I was alone.', transcript: 'no' },
            ]
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        if (this.options[0].selected) {
            return CallReportVoiceInputSteps.InterviewersNames;
        } else if (this.options[1].selected) {
            return CallReportVoiceInputSteps.IntervieweesReview;
        }
        return this.step;
    }
    
    override getValue(data: CallReportEditData): string {
        return data.interviewers.length > 0 ? 'yes' : '';
    }
}

export class InterviewersNamesStep extends CallReportVoiceInputStep {
    
    constructor() {
        super(
            CallReportVoiceInputSteps.InterviewersNames,
            ''
        );
    }

    override formatText(data: CallReportEditData, index: number): string {
        const num = this._toOrdinal(index + 1);

        return `What was the name of the ${num} colleague?`;
    }
    
    override complete(data: CallReportEditData, transcript: string, index: number): CallReportVoiceInputSteps {
        if (!transcript) {
            return this.step + 1;
        }
        const list = data.interviewers;
        const i = index;
        if (!list[i]) {
            list[i] = {name: '', title: ''};
        }
        list[i].name = transcript;
        return this.step;
    }

    override getValue(data: CallReportEditData, index: number): string {
        return data.interviewers[index]?.name ?? '';
    }

    private _toOrdinal(num: number) {
        return new OrdinalLongPipe().transform(num);
    }
}