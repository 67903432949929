<div class="header" qa="sel2-off">
    <strong>
        {{ step }}. Select the job-to-be-done
    </strong> with {{ offering.name }}@if (company) { that you plan to discuss with {{ company.name }}}.
</div>


@for (x of offering.jobs; track x; let index = $index) {
<!--    <a class="sp-selectable mobile-layout" (click)="next(x)">{{ x }}</a>-->

    <div class="sp-selectable" [class.selected]="index == selectedIndex" [attr.qa]="'job-' + (index + 1)">
        <input type="radio" id="job-{{index}}" [value]="index" [(ngModel)]="selectedIndex" qa="inpt-off">
        <label for="job-{{index}}" qa="inpt-lbl">{{ x }}</label>
    </div>
} @empty {
}

<button (click)="onAddNew.emit()" type="button" class="alternative">
    <i class="material-icons">add</i>
    <span class="text" qa="add-job">Add @if (offering.jobs.length > 0) { another } job-to-be-done</span>
</button>

@if (!offering.jobs.length) {
    <hr>
    <a class="sp-link skip" (click)="onSkip.emit()">Skip</a>
}

<div class="header" qa="sel-jf">
    @if (aiGuideMode) {
        <strong>
            {{ step }}a. Optional: Enter the job function of AI customer,
        </strong>e.g. <em>design engineer</em> or <em>hospital administrator</em>. 
        This creates a more focused SalesPrep report reflecting their interests more than their entire company.
    } @else {
        <strong>
            {{ step }}a. Optional: Who will you meet with?
        </strong>
        This creates a SalesPrep report focused on <em>this</em> stakeholder's interests... 
        and helps SalesPrep's AI Ghostwriter (Paige) prepare your call report.
    }
</div>


@for (role of customers; track role; let index = $index) {
    <div class="role" [attr.qa]="'role-' + (index + 1)">
        
        @if (!aiGuideMode) {
            <div class="flex-column">
                <label [for]="'name' + index">First & last name</label>
                <input appAutofocus [autofocus]="!role.name" [id]="'name-' + index" [autofocus]="!role.name" type="text" 
                       [(ngModel)]="role.name" required 
                       qa="name">
            </div>
        }
        
        <div class="flex-column">
            <label [for]="'role' + index">Role (e.g. design engineer)</label>
            <input [id]="'role-' + index" [autofocus]="!role.title" type="text" [(ngModel)]="role.title" required
                   qa="role">
        </div>

        <a (click)="removeRole(index)" class="remove" title="remove role">
            <span class="material-symbols-outlined" qa="role-x">close</span>
        </a>
    </div>
}

<button (click)="addRole()" type="button" class="add-role alternative">
    <i class="material-icons">add</i>
    <span class="text" qa="add-role">Add customer</span>
</button>


@if (!aiGuideMode) {
    <div class="header sp-checkbox flex" qa="list-stakeholders">
        <input style="margin-right: 10px;" type="checkbox" [(ngModel)]="stakeholdersEnabled">
        <div qa="txt">
            <strong>
                {{ step }}b. Optional: List company stakeholders
            </strong>
            — those job functions who will help decide which offering to purchase
        </div>
    </div>
}

<app-step-buttons 
                  (onBack)="onBack.emit()" 
                  (onNext)="next(offering.jobs[selectedIndex])"
                  [nextEnabled]="selectedIndex !== -1" />
