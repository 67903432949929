<label for="call-report-text"><span qa="lbl-t">{{ title }}</span> <app-stt-input-toggle/></label>

<form #textForm="ngForm" (ngSubmit)="next(textForm)">
    <textarea appAutofocus appAutosize [(ngModel)]="text" (ngModelChange)="change()" required name="text" id="call-report-text" qa="text"></textarea>
</form>

<div class="note" qa="note">
    @if (step == 4) {
        If not, just click Generate Call Report to review and edit it.
    } @else {
        If not, just click next.
    }
</div>

@if (generatingReport) {
    <app-spinner/>
} @else {
    <app-form-navigation-buttons [nextTitle]="step == 4 ? 'Generate Call Report' : 'Next'" (onPrev)="onPrev.emit()" (onNext)="onNext.emit()" qa="navi"/>
}