import {ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from "@angular/common";

@Component({
    selector: 'app-recording-animation',
    standalone: true,
    imports: [],
    templateUrl: './recording-animation.component.html',
    styleUrl: './recording-animation.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RecordingAnimationComponent implements OnInit {
    static _loaded = false;

    constructor(@Inject(DOCUMENT) private readonly document: Document) {        
    }

    ngOnInit() {
        if (!RecordingAnimationComponent._loaded) {
            const script = document.createElement('script');
            script.src = '/assets/animations/lottie-player.js';
            this.document.head.appendChild(script);
            
            RecordingAnimationComponent._loaded = true;
        }
    }

}
