@if (company) {
    <app-company-logo [company]="company"/>
}

<div class="text" qa="div-comp">
    <div class="company" qa="comp-name">{{ company?.name }}</div>
    @if (!job) {
        <div class="offering" qa="off-name">{{ offering?.name }}</div>
    }
    
    @if (meetingDate) {
        <div class="date" qa="md-sb-date">
            {{ meetingDate | date:'MMM' }} <span class="number" qa="md-sb-t">{{ meetingDate | date:'dd, YYYY' }} {{ meetingTime | time }}</span>
            @if (customerLocation) {
                | {{ customerLocation }}
            }
        </div>
    }
    @if (job && offering) {
        <app-report-subject 
                [offeringName]="offering.name"
                [offeringType]="offering.type"
                [job]="job"
                qa="rep-subj"
        />
    }
</div>
