import {CallReportVoiceInputStep, CallReportVoiceInputStepWithOptions} from "./model";
import {CallReportVoiceInputSteps} from "./call-report-voice-input-steps";
import {CallReportEditData} from "../../model";
import {OrdinalLongPipe} from "../../pipes/ordinal-long.pipe";

/*
export class IntervieweesCountStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.IntervieweesCount,
            'How many customers did you meet with?'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {        
        return CallReportVoiceInputSteps.IntervieweesCount;
    }
}
*/


export class IntervieweesReviewStep extends CallReportVoiceInputStepWithOptions {
    constructor() {
        super(
            CallReportVoiceInputSteps.IntervieweesReview,
            'Here’s what I have for the customers:\n{LIST}\nIs this still correct as is?',
            [
                { title: '<em>Yes</em>. It is correct as is.', transcript: 'yes' },
                { title: '<em>No</em>. I need to update it.', transcript: 'no' },
            ]
        );
    }

    override formatText(data: CallReportEditData): string {
        const customers = data.interviewees.filter(x => !!x.name || !!x.title);
        if (!customers.length) {
            return 'You didn\'t specify the customers in Pre Call Report.\nIs this still correct as is?';
        }
        
        let list = '';        
        for (let i = 0; i < customers.length; i++) {
            const x = customers[i];
            if (x.name && x.title) {
                list += `• ${x.name}, ${x.title}\n`;
            }
        }
        
        return this.text.replace('{LIST}', list);
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        if (this.options[0].selected) {
            return CallReportVoiceInputSteps.ReviewMeetingOverviewQuestion;
        } else if (this.options[1].selected) {
            return CallReportVoiceInputSteps.IntervieweesStep;
        }
        return this.step;
    }

    override getValue(data: CallReportEditData, index: number): string {
        // we already have been past that step, so we can default to yes in here
        return data.interviewers.length > 0 ? 'yes' : '';
    }
}

export class IntervieweesStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.IntervieweesStep,
            ''
        );
    }

    override formatText(data: CallReportEditData, index: number): string {
        const num = this._toOrdinal(index + 1);
        
        return !data.interviewees.length
            ? `What was the name and role of the ${num} customer you met?`
            : `What was the name and role of the ${num} customer?`
    }

    override complete(data: CallReportEditData, transcript: string, index: number): CallReportVoiceInputSteps {
        if (!transcript) {
            return this.step + 1;
        }
        const list = data.interviewees;
        const i = index;
        if (!list[i]) {
            list[i] = {name: '', title: ''};
        }

        const args = transcript.split(',');
        list[i].name = args[0];
        list[i].title = args[1];
        return this.step;
    }

    private _toOrdinal(num: number) {
        return new OrdinalLongPipe().transform(num);
    }

    override getValue(data: CallReportEditData, index: number): string {
        const i = data.interviewees[index];
        if (!i) return '';
        
        if (i.name) {
            if (i.title) {
                return `${i.name}, ${i.title}`;
            }
            return i.name;
        }
        return i.title;
    }
}