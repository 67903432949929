import {Component, Input} from '@angular/core';
import {Offering} from "../../../../model/offering";
import {Company} from "../../../../model";
import {DatePipe} from "@angular/common";
import {ReportSubjectComponent} from "../../../../components/report-subject/report-subject.component";
import {CompanyLogoComponent} from "../../../../components/company-logo/company-logo.component";
import {TimePipe} from "../../../../pipes/time.pipe";

@Component({
    selector: 'app-report-builder-header',
    standalone: true,
    imports: [
        ReportSubjectComponent,
        CompanyLogoComponent,
        DatePipe,
        TimePipe
    ],
    templateUrl: './report-builder-header.component.html',
    styleUrl: './report-builder-header.component.scss'
})
export class ReportBuilderHeaderComponent {
    @Input() company!: Company | undefined;
    @Input() offering!: Offering | undefined;
    @Input() job!: string | undefined;
    @Input() customerLocation: string | undefined;    
    @Input() meetingDate: Date | undefined;
    @Input() meetingTime: string | undefined;
}
