import {CallReportVoiceInputStep, CallReportVoiceInputStepWithOptions} from "./model";
import {CallReportVoiceInputSteps} from "./call-report-voice-input-steps";
import {CallReportEditData} from "../../model";

export class MeetingPurposeStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.MeetingPurpose,
            'May I ask what the purpose of your meeting was?'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        data.purpose = transcript;
        return CallReportVoiceInputSteps.ScopeReview;
    }

    override getValue = (data: CallReportEditData) => data.purpose;
}

export class ScopeReviewStep extends CallReportVoiceInputStepWithOptions {
    constructor() {
        super(
            CallReportVoiceInputSteps.ScopeReview,
            'Here’s what I have for the scope:\n“{SCOPE}”\nIs this still correct as is?',
            [
                { title: '<em>Yes</em>. It is correct as is.', transcript: 'yes' },
                { title: '<em>No</em>. I need to update it.', transcript: 'no' },
            ]
        );
    }

    override formatText(data: CallReportEditData): string {
        return this.text.replace('{SCOPE}', data.scope);
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        if (this.options[0].selected) {
            return !!data.interviewers.length
                ? CallReportVoiceInputSteps.InterviewersNames
                : CallReportVoiceInputSteps.Interviewers;
        } else if (this.options[1].selected) {
            return CallReportVoiceInputSteps.ScopeUpdate;
        }
        return this.step;
    }
    
    override getValue(data: CallReportEditData, index: number): string {
        // we already have been past that step, so we can default to yes in here
        return data.interviewers.length > 0 ? 'yes' : '';
    }
}

export class ScopeUpdateStep extends CallReportVoiceInputStep {
    constructor() {
        super(
            CallReportVoiceInputSteps.ScopeUpdate,
            'Okay, no problem. What is the correct scope of your meeting?'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        if (!transcript) return this.step;
        data.scope = transcript;
        return !!data.interviewers.length
            ? CallReportVoiceInputSteps.InterviewersNames
            : CallReportVoiceInputSteps.Interviewers;
    }

    override getValue = (data: CallReportEditData) => data.scope;
}
