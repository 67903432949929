import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {RecordingAnimationComponent} from "../recording-animation/recording-animation.component";


@Component({
    selector: 'app-voice-input-toggle',
    standalone: true,
    imports: [
        RecordingAnimationComponent
    ],
    templateUrl: './voice-input-toggle.component.html',
    styleUrl: './voice-input-toggle.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoiceInputToggleComponent {
    
    @HostBinding('class.listening') @Input() listening = false;

}
