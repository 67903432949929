<app-main-header class="sp-desktop"/>

<app-main-title class="mobile-layout" (onAction)="showHome()" [customAction]="true">
    Role Play with Claire —
    @if (job) {
        @switch (tutorLevel) {
            @case (TutorCoachingLevels.Beginner) {
                <span class="novice">Novice</span>
            }
            @case (TutorCoachingLevels.Intermediate) {
                <span class="intermediate">Intermediate</span>
            }
            @case (TutorCoachingLevels.Expert) {
                <span class="expert">Expert</span>
            }
        }
    } @else {
        your AI Guide
    }
</app-main-title>


<app-page-header class="desktop-layout" 
                 [title]="'Role-play with Claire — your AI Guide'" 
                 [text]="subject">

    <div class="header-actions">        
        <app-text-to-speech-toggle [notSupported]="speechNotSupported" [speechEnabled]="speechEnabled" (onToggle)="speechEnabledChanged()" (onSettings)="showSettings()" qa="tts" />        
    </div>
    
    <div class="claire">
        <div class="claire-wrapper">
            <app-tutor-avatar />
    
            @if (!job) {
                <div qa="t1">
                    Hello! I’m Claire and I’ll be your AI Guide in this practice session. I’ll offer just the amount of guidance that you’d like. 
                    
                    <br><br>If you want a little refresher on how the role-playing works, 
                    <a class="sp-link" routerLink="/ai-guide/try">click here</a>.
                </div>
            }
        </div>
    </div>
</app-page-header>



<div class="container">
    <div class="wrapper">
        @if (job) {
            @if (!tutorService.started() && !tutorService.starting()) {
                <app-main-panel class="sp-form select-level-header mobile-layout" >
                    <div class="claire-mobile-header">
                        <app-tutor-avatar />
    
                        @if (offering) {
                            <div qa="t2">                            
                                For this session, you'll practice offering <span class="extra-bold">{{offering.name}}</span> @if (job) { {{offering.type | offeringAction}} {{job}} }.
                                @if (tutorService.customers().length > 1) {
                                    <br qa="cust-r">Customer Roles:
                                    @for (role of tutorService.customers(); track role; let last = $last) {
                                        <span qa="ro">{{ role.title }}</span>@if (!last) {,}
                                    }
                                }                                
                            </div>                            
                        }

                        <app-text-to-speech-toggle [notSupported]="speechNotSupported" [speechEnabled]="speechEnabled" (onToggle)="speechEnabledChanged()" (onSettings)="showSettings()" />                        
                        
                    </div>
                </app-main-panel>
            }
            
            <app-pure-tutor-chat
                (onStart)="sendToTutor($event)"
                (onSend)="sendToTutor($event)"
    
                (onRolePlayAgain)="rolePlayAgain()"
                
                [inlineMode]="true"
                [authenticated]="authenticated()"
                [userInitials]="userInitials()"
                [(coachingLevel)]="tutorLevel"
        
                [offeringName]="offering!.name"
                [offeringType]="offering!.type"
                [job]="job"
                [topic]="topic"
    
                [what]="tutorService.numWhat()"
                [why]="tutorService.numWhy()"
                [clarify]="tutorService.numClarify()"
                [problem]="tutorService.numProblem()"
                
                [started]="tutorService.started()"
                [completed]="tutorService.completed()"
                [starting]="tutorService.starting()"
                [loading]="tutorService.loading()"
                [messages]="tutorService.messages()"
                
                [advicesLoading]="tutorService.advicesLoading()"
                [advices]="tutorService.advices()"
                [strengths]="tutorService.strengths()"
                
                [speechEnabled]="speechEnabled"
            />
        } @else {
            <app-main-panel class="sp-form">
                <div class="claire-mobile-header mobile-layout">
                    <app-tutor-avatar />

                    @if (!job) {
                        <div qa="t3">
                            Hello! I’m Claire and I’ll be your AI Guide in this practice session. I’ll offer just the amount of guidance that you’d like.

                            <br><br>If you want a little refresher on how the role-playing works,
                            <a class="sp-link" routerLink="/try-ai-guide">click here</a>.
                        </div>
                    }
                </div>
                
                @if (reportMode) {
                    @if (reportsLoading()) {
                        <app-spinner/>
                    } @else {
                        <app-select-report 
                                (onSelectOffering)="reportMode = false"
                                (onSelectReport)="selectReport($event)"
                                [reports]="reports()" />
                    }
                } @else {
                    @if (offering) {                
                            <app-select-job 
                                (onSelect)="setJob($event)" 
                                (onSkip)="setJob({job: '_', customers: []})"
                                (onAddJob)="addJob($event)"
                                [offering]="offering"
                            />
                    } @else {
                        @if (userProfile()) {
                            <app-select-offering 
                                    (onSelectReport)="reportMode = true"
                                    (onSelectOfferingType)="setCategories($event)"
                                    (onSelect)="setOffering($event)"
                                    (onAdd)="addOffering($event)"
                                    [categories]="categories()"
                                    [offerings]="offerings()" />
                        }
                    }
                }
            </app-main-panel>
        }
    </div>
</div>

@if (showVoiceSettings) {
    <app-info-panel [title]="'Review voices available in your browser'" (onClose)="showVoiceSettings = false">
        <app-text-to-speech-settings
                (onReset)="resetVoices()"
                (onPlayVoice)="playVoice($event)"
                (onSelectVoice)="selectVoice($event)"
                
                [playingVoice]="playingVoice"
                [voices]="voices" 
                [selectedVoices]="selectedVoices()"/>        
    </app-info-panel> 
}