import {CallReportVoiceInputStep, CallReportVoiceInputStepWithOptions} from "./model";
import {CallReportVoiceInputSteps} from "./call-report-voice-input-steps";
import {CallReportEditData, CallReportRecodedOutcome} from "../../model";
import {OrdinalLongPipe} from "../../pipes/ordinal-long.pipe";

export class OutcomesQuestionStep extends CallReportVoiceInputStepWithOptions {
    constructor() {
        super(
            CallReportVoiceInputSteps.OutcomesQuestion,
            '',
            [
                { title: '<em>Yes</em>.', transcript: 'yes' },
                { title: '<em>No</em>. Skip this step.', transcript: 'no' },
            ]
        );
    }
    
    override formatText(data: CallReportEditData, index: number): string {
        return !data.outcomes.length 
            ? 'Did you uncover any desired customer outcomes?\n\nThese could be problems they want fixed or improvements they’d like.'
            : 'Did you uncover any additional customer outcomes?';
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        if (this.options[0].selected) {
            return CallReportVoiceInputSteps.OutcomeWhat;
        } else if (this.options[1].selected) {
            return CallReportVoiceInputSteps.Insights;
        }
        return this.step;
    }
}

export class OutcomeWhatStep extends CallReportVoiceInputStep {
    outcome!: CallReportRecodedOutcome;
    
    constructor() {
        super(
            CallReportVoiceInputSteps.OutcomeWhat,
            ''
        );
    }

    override formatText(data: CallReportEditData): string {
        let i = data.outcomes.indexOf(this.outcome);
        if (i == -1) {
            i = data.outcomes.length;
        }
        const num = this._toOrdinal(i + 1);
        return `Can you describe the ${num} outcome?`;
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        if (!transcript) return this.step;
        this.outcome.what = transcript;
        // data.outcomes[data.outcomes.length - 1].what = transcript;
        return CallReportVoiceInputSteps.OutcomeWhy;
    }

    private _toOrdinal(num: number) {
        return new OrdinalLongPipe().transform(num);
    }

    override getValue =() => this.outcome?.what;
}


export class OutcomeWhyStep extends CallReportVoiceInputStep {
    outcome!: CallReportRecodedOutcome;
    
    constructor() {
        super(
            CallReportVoiceInputSteps.OutcomeWhy,
            'How does it impact them?'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        this.outcome.why = transcript;
        // data.outcomes[data.outcomes.length - 1].why = transcript;
        return CallReportVoiceInputSteps.OutcomeClarify;
    }
    
    override getValue =() => this.outcome?.why;
}

export class OutcomeClarifyStep extends CallReportVoiceInputStep {
    outcome!: CallReportRecodedOutcome;
    
    constructor() {
        super(
            CallReportVoiceInputSteps.OutcomeClarify,
            'The customer would like to…'
        );
    }

    override complete(data: CallReportEditData, transcript: string): CallReportVoiceInputSteps {
        this.outcome.clarify = transcript;

        /*const i = data.outcomes.indexOf(this.outcome) + 1;
        if (i < data.outcomes.length) {
            return CallReportVoiceInputSteps.OutcomeWhat;    
        }*/
        return CallReportVoiceInputSteps.OutcomesQuestion;
    }

    override getValue =() => this.outcome?.clarify;
}