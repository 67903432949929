import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TriggerDownloadService {

    download(content: any, filename: string, mimeType: string = 'application/octet-stream') {
        const blob = new Blob([content], { type: mimeType });

        const legacy = (<any>window.navigator).msSaveOrOpenBlob;
        if (legacy) {
            legacy(blob, filename);
        } else {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            window.URL.revokeObjectURL(elem.href);
            document.body.removeChild(elem);
        }
    }
}
