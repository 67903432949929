import {Component, OnInit} from '@angular/core';
import {
    CallReportBuilderHeaderComponent
} from "../../components/call-report-builder-header/call-report-builder-header.component";
import {TextFormComponent} from "../../components/text-form/text-form.component";
import {ActivatedRoute, Router} from "@angular/router";
import {CallReportBuilderService} from "../../../../services/call-report-builder.service";
import {VoiceInputPanelComponent} from "../../components/voice-input-panel/voice-input-panel.component";
import {SpeechSynthesisService} from "../../../../services/speech-synthesis.service";
import {
    CallReportVoiceInputService
} from "../../../../services/call-report-voice-input/call-report-voice-input.service";
import {SpeechRecognitionService} from "../../../../services/speech-recognition.service";
import {CallReportVoiceInputSteps} from "../../../../services/call-report-voice-input";


@Component({
    selector: 'app-notes-screen',
    standalone: true,
    imports: [
        CallReportBuilderHeaderComponent,
        TextFormComponent,
        VoiceInputPanelComponent,
    ],
    templateUrl: './notes-screen.component.html',
    styleUrl: './notes-screen.component.scss'
})
export class NotesScreenComponent implements OnInit {

    data = this.reportBuilder.inputData;
    generatingReport = this.reportBuilder.generating;

    voiceInput = this.reportBuilder.voiceInput;
    currentStep = this.callReportVoiceInputService.currentStep;
    currentStepText = this.callReportVoiceInputService.currentStepText;
    currentStepOptions = this.callReportVoiceInputService.currentStepOptions;

    step = 1;
    STEPS_COUNT = 4;

    constructor(private reportBuilder: CallReportBuilderService,
                protected callReportVoiceInputService: CallReportVoiceInputService,
                protected speechSynthesisService: SpeechSynthesisService,
                protected speechRecognitionService: SpeechRecognitionService,
                private route: ActivatedRoute,
                private router: Router) {
    }
    
    ngOnInit() {
        this.route.params.subscribe(params => {
            const i = +this.route.snapshot.params['step'] || 1;

            if (this.step != i) {
                this.step = i;
                this.callReportVoiceInputService.setOtherThingsStep(i);
            }
        })
    }

    prev() {
        if (this.step > 1) {
            this.speechRecognitionService.stop();
            // this.callReportVoiceInputService.prev();
            this._updateUrl(this.step - 1);
        } else {
            const count = this.data()?.outcomes.length || 1;
            this.router.navigate(['../../outcomes', count], {relativeTo: this.route});
        }
    }

    next() {
        this.voiceNext('');        
    }

    private _updateUrl(index: number) {
        this.reportBuilder.saveInputData();
        this.router.navigate(['..', index], {relativeTo: this.route});
    }

    setInput(voiceInput: boolean) {
        const s = this.callReportVoiceInputService;
        if (voiceInput) {
            s.currentStep.set(s.steps[CallReportVoiceInputSteps.Insights]);
        }
        setTimeout(() => this.reportBuilder.setVoiceInput(voiceInput), 500);
    }

    voiceNext(text: string) {
        if (this.step < this.STEPS_COUNT) {
            if (text) {
                this.callReportVoiceInputService.next(text);
            }
            this._updateUrl(this.step + 1);
        } else {
            this.reportBuilder.generateReport()
                .subscribe(report => {
                    if (report) {
                        this.reportBuilder.generationComplete(report);
                        this.router.navigate(['/call-report', this.reportBuilder.reportId()]);
                    }
                });
        }
    }
}
