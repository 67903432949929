@if (!inlineMode) {
    <app-top-progress-bar [step]="6" />
    <app-side-progress-bar [step]="6" [company]="company" [offering]="product" [job]="job" />
}

<app-main-panel [class.inline]="inlineMode" class="left-column">
    
    <form (ngSubmit)="save()">
        
    @if (!inlineMode) {
        <div class="flex mobile-layout">
            <app-report-builder-header [company]="company" [offering]="product" [job]="job"/>
        </div>
    }
    
    <div class="sp-form">
        
        <div class="inputs">
            <div class="title" qa="type">
                <strong>6a. Will this meeting be in-person or remote (phone or video)?</strong>
            </div>
            <div class="sp-selectable" qa="in-person">
                <input type="radio" [value]="MeetingType.InPerson" id="in-person" name="meeting-type" [(ngModel)]="meetingType" qa="input">
                <label for="in-person" qa="label">{{ MeetingType.InPerson | meetingType }}</label>
            </div>
            <div class="sp-selectable" qa="remote">
                <input type="radio" [value]="MeetingType.Virtual" id="remote" name="meeting-type" [(ngModel)]="meetingType" qa="input">
                <label for="remote" qa="label">{{ MeetingType.Virtual | meetingType }}</label>
            </div>
            <div class="sp-selectable" qa="unsure">
                <input type="radio" [value]="MeetingType.Unknown" id="unsure" name="meeting-type" [(ngModel)]="meetingType" qa="input">
                <label for="unsure" qa="label">Unsure</label>
            </div>            

            <div class="title time" qa="location">
                <strong>6b. Location of the customer with whom you are meeting</strong>
            </div>
            
            <div class="company-location flex">
                <div class="flex-column">
                    <label for="city">City</label>
                    <input id="city" type="text" name="city" [(ngModel)]="customerCity" qa="city" placeholder="City">
                </div>
    
                <div class="flex-column">
                    <label for="state">State or Country</label>
                    <input id="state" type="text" name="state" [(ngModel)]="customerState" qa="state" placeholder="State or Country">
                </div>
            </div>
            
            
            <div class="title time" qa="date">
                <strong>6c. Select your meeting date.</strong>
            </div>
        
            <div class="sp-selectable" qa="today">
                <input type="radio" value="{{today | date:'yyyy-MM-dd'}}" id="date-today" checked="checked" name="date" [(ngModel)]="selectedDate" qa="input">
                <label for="date-today" qa="label">Today, {{today | date:"MMM d"}}</label>
            </div>
    
            <div class="sp-selectable" qa="tom">
                <input type="radio" value="{{tomorrow | date:'yyyy-MM-dd'}}" id="date-tomorrow" name="date" [(ngModel)]="selectedDate" qa="input">
                <label for="date-tomorrow" qa="label">Tomorrow, {{tomorrow | date:"MMM d"}}</label>
            </div>
            
            <div class="sp-selectable" qa="select" (click)="showDatePicker = true">
                <input type="radio" [checked]="!!selectedDate" value="custom" id="date-custom" name="date" #customDate [(ngModel)]="selectedDate" qa="input1">
    <!--            <input type="text" placeholder="Pick date" [(ngModel)]="date" (focus)="customDate.checked = true; selectedDate = 'custom'" qa="input2">-->
                
                <label for="date-custom" qa="label">
                    @if (datePickerValue) {
                        {{datePickerValue | date: "MMM dd, yyyy"}}
                    } @else {
                        Pick date
                    }
                </label>
                <span (click)="showDatePicker = true;showTimePicker = false;" class="material-icons">calendar_today</span>
            </div>
    
            <div class="sp-selectable" qa="t-unsure">
                <input type="radio" value="" id="date-unsure" name="date" [(ngModel)]="selectedDate" qa="input">
                <label for="date-unsure" qa="label">Unsure</label>
            </div>
                
            <div class="title time" qa="t-time">
                <strong>6d. Set your meeting time.</strong>
            </div>
            
            <div class="sp-selectable" qa="select2" (click)="showTimePicker = true">
                <input type="radio" value="custom" id="time-custom" checked="checked" name="time" #customTime [(ngModel)]="selectedTime" qa="input1">
    <!--            <input type="time" step="900" [(ngModel)]="time" (focus)="customTime.checked = true; selectedTime = 'custom'" qa="input2">-->
    
                <label for="time-custom" qa="label">
                    @if (time) {
                        {{ time | time }}
                    } @else {
                        Pick time
                    }
                </label>
    <!--            <label for="time">{{time}}</label>-->
    <!--            <span (click)="showTimePicker = true;showDatePicker = false;" class="material-icons">schedule</span>-->
            </div>
            
            <div class="sp-selectable" qa="unsure">
                <input type="radio" value="" id="time-unsure" name="time" [(ngModel)]="selectedTime" qa="input">
                <label for="time-unsure" qa="label">Unsure</label>
            </div>
        </div>
    
        <div class="actions" qa="actions">
            @if (inlineMode) {
                <button type="button" (click)="onCancelEditMode.emit()" class="back desktop-layout" qa="back">
                    Back
                </button>
            } @else {
                <button [routerLink]="['/report-builder', 'questions']" class="back desktop-layout" qa="back">
                    Back
                </button>
            }
            
            <button class="primary" type="submit" [disabled]="saving || (selectedDate == 'custom' && !this.date)" qa="save">
                Save @if (!inlineMode) { &amp; View Report }
            </button>
        </div>
    </div>

    </form>
</app-main-panel>

@if (showDatePicker) {
    <app-info-panel [title]="'Select Date'" (onClose)="showDatePicker = false">
        <app-date-picker [(date)]="datePickerValue" (dateChange)="showDatePicker = false"/>        
    </app-info-panel>
}
@if (showTimePicker) {
    <app-info-panel [title]="'Select Time'" (onClose)="showTimePicker = false">
        <app-time-picker [(time)]="time" (timeChange)="selectedTime = 'custom'" (onTimSelected)="showTimePicker = false" />
    </app-info-panel>
}
