import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {MeetingType, SalesPrepReport} from "../../../model";
import {IDropdownItem} from "../dropdown/dropdown.component";
import {DateFilterComponent, DateFilterType, IDateFilterValue} from "../date-filter/date-filter.component";
import {MultiselectComponent} from "../multiselect/multiselect.component";
import {ReportsFilterService} from "../services/reports-filter.service";
import {CustomerLocationPipe} from "../../../pipes/customer-location.pipe";
import {MeetingTypePipe} from "../../../pipes/meeting-type.pipe";

@Component({
    selector: 'app-reports-filter',
    standalone: true,
    imports: [
        DateFilterComponent,
        MultiselectComponent
    ],
    templateUrl: './reports-filter.component.html',
    styleUrl: './reports-filter.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportsFilterComponent implements OnChanges {
    @Input() reports: SalesPrepReport[] = [];
    
    @Input() filter: IReportFilter = ReportsFilterComponent.getEmptyFilters();
    
    companiesItems: IDropdownItem[] = [];
    rolesItems: IDropdownItem[] = [];
    meetingTypesItems: IDropdownItem[] = [];
    locationsItems: IDropdownItem[] = [];
    offeringsItems: IDropdownItem[] = [];

    @Output() filterChange = new EventEmitter<IReportFilter>();
    
    constructor(private reportsFilterService: ReportsFilterService) {
    }
    
    
    public static getEmptyFilters(): IReportFilter {
        return {
            selectedCompany: [],
            meetingDateFilter: { filterType: DateFilterType.NotSet },
            roles: [],
            offerings: [],
            meetingTypes: [],
            locations: [],
            // lastViewedFilter: { filterType: DateFilterType.NotSet },            
            // lastModifiedFilter: { filterType: DateFilterType.NotSet },
        }
    } 
    
    
    ngOnChanges() {
        this._init();
    }

    updateFilter() {
    }
    
    private _locationPipe = new CustomerLocationPipe();
    
    private _init() {
        this.companiesItems = [];
        this.rolesItems = [];
        this.offeringsItems = [];
        this.locationsItems = [{
            value: '',
            text: 'Not Set'
        }];
        this.meetingTypesItems = [{
            value: MeetingType.InPerson,
            text: 'In-person'
        }, {
            value: MeetingType.Virtual,
            text: 'Remote'
        }, {
            value: MeetingType.Unknown,
            text: 'Not Set'
        }];
        
        this.reports.forEach(r => {

            const company = r.company;
            if (!this.companiesItems.find(y => y.value.id == company.id)) {
                this.companiesItems.push({
                    value: company,
                    text: company.name
                });
            }
            
            r.customers.map(x => x.title).filter(x => !!x).forEach(role => {
                const _role = this._capitalize(role);
                if (!this.rolesItems.find(x => x.value == _role)) {
                    this.rolesItems.push({
                        value: _role
                    })
                }
            });
            
            const o = this._capitalize(r.offeringName);
            if (!this.offeringsItems.find(x => x.value == o)) {
                this.offeringsItems.push({
                    value: o
                })
            }

            if (r.customerCity || r.customerState) {
                const value = this._capitalize(this._locationPipe.transform(r));
                
                if (!this.locationsItems.find(x => x.value == value)) {
                    this.locationsItems.push({                        
                        value: value
                    })
                }
            }

            /*if (!this.meetingTypesItems.find(x => x.value == r.meetingType)) {
                this.meetingTypesItems.push({
                    value: r.meetingType,
                    text: this._locationPipe.transform(r.meetingType) ?? 'Unset'
                })
            }*/
        });

        this.companiesItems.sort((x, y) => {
            const a = x.value.name;
            const b = y.value.name;

            if(a < b) { return -1; }
            if(a > b) { return 1; }
            return 0;
        });
        
        this.rolesItems.sort(this._sortByValue);
        this.offeringsItems.sort(this._sortByValue);
        this.locationsItems.sort(this._sortByValue);
        this.meetingTypesItems.sort(this._sortByValue);
    }
    
    private _capitalize(str: string) {
        if (str.length < 1) {
            return str;
        }
        
        return str[0].toUpperCase() + str.substring(1);        
    }
    
    private _sortByValue(x: IDropdownItem, y: IDropdownItem) {
        const a = x.value;
        const b = y.value;

        if(a < b) { return -1; }
        if(a > b) { return 1; }
        
        return 0;        
    }

    clearFilters() {
        this.filter = ReportsFilterComponent.getEmptyFilters()
        this.applyFilters();
    }

    applyFilters() {
        this.filterChange.emit(this.filter);
    }
}

export interface IReportFilter {
    selectedCompany: IDropdownItem[];
    meetingDateFilter: IDateFilterValue;
    roles: IDropdownItem[];
    meetingTypes: IDropdownItem[];
    locations: IDropdownItem[];
    offerings: IDropdownItem[];
    // lastViewedFilter: IDateFilterValue;
    // lastModifiedFilter: IDateFilterValue;
}