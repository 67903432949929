import * as step from './steps';
import {CallReportVoiceInputStep} from "./model";
import * as interviewers from "./interviewers-steps";
import * as interviewees from "./interviewees-steps";
import * as scope from "./scope-steps";
import * as outcomes from "./outcomes-steps";

export class CallReportVoiceInputStepsList {
    
    static steps: CallReportVoiceInputStep[] = [
        new step.StartCallReportStep(),
        new step.CustomerLocationStep(),
        new step.MeetingTypeStep(),
        
        new scope.MeetingPurposeStep(),
        new scope.ScopeReviewStep(),
        new scope.ScopeUpdateStep(),
        
        new interviewers.InterviewersStep(),
        new interviewers.InterviewersNamesStep(),
        
        new interviewees.IntervieweesReviewStep(),
        new interviewees.IntervieweesStep(),
        
        new step.ReviewMeetingOverviewQuestionStep(),
        new step.ReviewMeetingOverviewStep(),
        
        new outcomes.OutcomesQuestionStep(),
        new outcomes.OutcomeWhatStep(),
        new outcomes.OutcomeWhyStep(),
        new outcomes.OutcomeClarifyStep(),        
        
        new step.InsightsStep(),
        new step.OpportunitiesStep(),
        new step.NextStepsStep(),
        new step.NotesStep(),
    ];    
}
