import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input, OnChanges,
    Output, SimpleChanges
} from '@angular/core';
import {ClickOutsideDirective} from "../../../directives/click-outside.directive";
import {IDropdownItem} from "../dropdown/dropdown.component";

@Component({
    selector: 'app-multiselect',
    standalone: true,
    imports: [
        ClickOutsideDirective
    ],
    templateUrl: './multiselect.component.html',
    styleUrl: './multiselect.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiselectComponent implements OnChanges {

    @Input() items: IDropdownItem[] = [];
    @Input() expanded = false;

    @Input() value: IDropdownItem[] = [];
    @Output() valueChange = new EventEmitter<IDropdownItem[]>();

    selectedValuesLabel = '';

    constructor(private cdRef: ChangeDetectorRef) {
    }

    ngOnChanges() {
        this._init();
    }

    private _init() {
        this.selectedValuesLabel = (this.value)
            ? this.value.map(x => x.text || x.value).join(', ')
            : '';
    }

    @HostListener('click') expand() {
        console.log('[MultiselectComponent] expand', this.expanded);
        this.expanded = !this.expanded;
        this.cdRef.detectChanges();
    }

    select(item: IDropdownItem | undefined) {

        this.expanded = false;
        // this.selectedItem = item;
        // this.selectedItemChange.emit(item);
    }

    collapse() {
        console.log('[MultiselectComponent] collapse');
        this.expanded = false;
    }

    selected(item: IDropdownItem) {
        return this.value.find(x => x.value === item.value);
    }

    toggle(e: MouseEvent, item: IDropdownItem) {
        e.preventDefault();
        e.stopPropagation();


        if (this.selected(item)) {
            this.value = this.value.filter(x => x.value !== item.value);
        } else {
            this.value.push(item);
        }
        this.valueChange.emit(this.value);
        this._init();
        this.cdRef.detectChanges();
    }
}
