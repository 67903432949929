import {Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output} from '@angular/core';
import {
    DiscussionTopicsListComponent
} from "../../../select-discussion-topics/components/discussion-topics-list/discussion-topics-list.component";
import {InfoPanelComponent} from "../../../../../../components/info-panel/info-panel.component";
import {MainPanelComponent} from "../../../../../../components/main-panel/main-panel.component";
import {
    ReportBuilderHeaderComponent
} from "../../../../components/report-builder-header/report-builder-header.component";
import {SpinnerComponent} from "../../../../../../components/spinner/spinner.component";
import {Company, MeetingType} from "../../../../../../model";
import {Offering} from "../../../../../../model/offering";
import {DatePipe} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {TopProgressBarComponent} from "../../../../components/top-progress-bar/top-progress-bar.component";
import {SideProgressBarComponent} from "../../../../components/side-progress-bar/side-progress-bar.component";
import {RouterLink} from "@angular/router";
import {DatePickerComponent} from "../../../../../../components/date-picker/date-picker.component";
import {TimePickerComponent} from "../../../../../../components/time-picker/time-picker.component";
import {TimePipe} from "../../../../../../pipes/time.pipe";
import {AutofocusDirective} from "../../../../../../directives/autofocus.directive";
import {MeetingTypePipe} from "../../../../../../pipes/meeting-type.pipe";

@Component({
    selector: 'app-pure-set-meeting-date',
    standalone: true,
    providers: [
        DatePipe
    ],
    imports: [
        InfoPanelComponent,
        MainPanelComponent,
        ReportBuilderHeaderComponent,
        FormsModule,
        DatePipe,
        TopProgressBarComponent,
        SideProgressBarComponent,
        RouterLink,
        DatePickerComponent,
        TimePickerComponent,
        TimePipe,
        AutofocusDirective,
        MeetingTypePipe
    ],
    templateUrl: './pure-set-meeting-date.component.html',
    styleUrl: './pure-set-meeting-date.component.scss'
})
export class PureSetMeetingDateComponent implements OnInit, OnChanges {
    
    @Input() inlineMode = false;
    @Input() company: Company | undefined;
    @Input() product!: Offering;
    @Input() job = '';
    
    @Input() saving = false;

    @Input() customerCity = '';
    @Input() customerState = '';
    @Input() meetingType = MeetingType.Unknown;
    @Input() date = '';
    @Input() time = '';

    selectedDate = '';
    selectedTime = '';
    
    today = new Date();
    tomorrow = new Date(+new Date() + 1000 * 60 * 60 * 24);
    
    private _today = '';
    private _tomorrow = '';
    
    @Input() showDatePicker = false;
    @Input() showTimePicker = false;
    @Output() onSave = new EventEmitter<SetMeetingDateArgs>();
    @Output() onCancelEditMode = new EventEmitter();

    @HostBinding('class.sp-screen') s = true;
    
    MeetingType = MeetingType;

    constructor(private datePipe: DatePipe) {
    }
    
    get datePickerValue() : Date | undefined {
        if (!this.selectedDate) {
            return undefined;
        }
        const args = this.date.split('-');
        if (args.length != 3) {
            return undefined;
        }
        return new Date(+args[0], +args[1] - 1, +args[2]);
    }
    
    set datePickerValue(date: Date | undefined) {
        if (!date) return;
        this.selectedDate = 'custom';
        this.date = this.datePipe.transform(date, "yyyy-MM-dd")!;
    }
    
    ngOnInit() {
        this._today = this.datePipe.transform(new Date(this.today), "yyyy-MM-dd")!;
        this._tomorrow = this.datePipe.transform(new Date(this.tomorrow), "yyyy-MM-dd")!;
    }
    
    ngOnChanges() {
        if (this.date == this._today || this.date == this._tomorrow) {
            this.selectedDate = this.date;
        } else {
            this.selectedDate = this.date ? 'custom' : '';
        }
        this.selectedTime = this.time ? 'custom' : '';        
    }

    save() {
        console.log('[SetMeetingDateComponent] save', this.meetingType, this.selectedDate, this.selectedTime);
        
        const date = this.selectedDate == 'custom' ? this.date : this.selectedDate;
        const time = this.selectedTime == 'custom' ? this.time : this.selectedTime;
        
        this.date = date;
        this.time = time;

        const customerCity = this.customerCity;
        const customerState = this.customerState;
        const meetingType = this.meetingType;
        
        this.onSave.emit({ customerCity, customerState, meetingType, date, time });
    }
}


export interface SetMeetingDateArgs {
    customerCity: string;
    customerState: string;
    meetingType: MeetingType;
    date: string;
    time: string;
}